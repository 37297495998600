$env: stage;
@import '@/styles/_component-base.styles.scss';

.hero {
    min-height: 375px;

    @include responsive-dpi-imgix('/generic/404-hero', '404-hero', 375, 768, 1440);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include breakpoint(md) {
        min-height: 467px;
    }

    @include breakpoint(lg) {
        min-height: 710px;
    }

}

.heading1 {
    font-size: set-fontSize(24);
    color: color(darkContrast, 2);
    @include breakpoint(md) {
        font-size: set-fontSize(36);
    }

    @include breakpoint(md) {
        font-size: set-fontSize(40);
    }
}

.heading2 {
    font-size: set-fontSize(20);
    color: color(darkContrast, 2);
    @include breakpoint(md) {
        font-size: set-fontSize(28);
    }

    @include breakpoint(md) {
        font-size: set-fontSize(32);
    }
}

.contactUs {
    margin: spacer(3xl) spacer(md);

    @include breakpoint(md) {
        margin-top: spacer(md);
        margin-right: auto;
        margin-left: auto;
        width: 578px;
    }
}

.noMargin {
    @include breakpoint(md) {
        margin-top: 0;
    }
}
